import * as React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "../Layout";

import NoPage from "../Pages/NoPage";
import Login from "../Pages/Login/Login";
import Home from "../Pages/FrontEndPages/Home";
import store from '../store';
import { Navigate } from 'react-router-dom';

// ---- Back End Lazy Loading -----
const Dashboard = React.lazy(() => import('../Pages/BackEndPages/Dashbaord/Dashbaord'))
const AvailableSlots = React.lazy(() => import('../Pages/Calender/AvailableSlot'))
const CreateEvent = React.lazy(() => import('../Pages/Calender/CreateEvent'))
const AvaibilityList = React.lazy(() => import('../Pages/Calender/AvaibilityList'))
const WebsiteManageListing = React.lazy(() => import('../Pages/BackEndPages/websiteManagement/listing/websiteManageListing'))
const AddEditWebsiteManage = React.lazy(() => import('../Pages/BackEndPages/websiteManagement/addEditForm/websitAddEdit'))
const WebsiteContentManageListing = React.lazy(() => import('../Pages/BackEndPages/websiteContentmanagement/websiteContentListing/websiteContentManageListing'))
const AddEditWebsiteContent = React.lazy(() => import('../Pages/BackEndPages/websiteContentmanagement/websiteContentAddEdit/websiteContentAddEdit'))
const Calender = React.lazy(() => import('../Pages/FrontEndPages/Calender'))
const UserManagementListing = React.lazy(() => import('../Pages/BackEndPages/userManagement/userManagementListing/userManagementListing'))
// -----------------------

// ---- Front End Lazy Loading -----
// const Home = React.lazy(() => import('../Pages/FrontEndPages/Home'))
// -------------------------------------

// const useAuth = () => {
//   // console.log("AUTHED++++++++++", store.getState().loggedinuser.isLoggedIn)
//   return store.getState().loggedinuser.isLoggedIn;
// }

// const PrivateRoute = ({ children }) => {
//   console.log("authed", useAuth());
//   const auth = useAuth();
//   return (
//     auth === true ? children : <Navigate to="/login" replace={true} />
//   )
// }

// const LoginRoute = ({ children, redirectPath }) => {
//   const auth = useAuth();
//   return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
// }





// const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
// console.warn("<<< userinfocookies goes here >>>>", userInfo.userinfocookies)

const GetRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>

        {/* --------------------Front-End Pages-------------------- */}

        <Route path="/home" element={<Suspense fallback={<></>}><Home /></Suspense>} />{" "}


        <Route path="/" index element={<Home />} />{" "}
        <Route path="login" index element={<Login />} />{" "}





        {/* ----- Backend Pages ------ */}
        <Route path="/" element={<Layout />}>

          <Route path="/admin/dashboard" element={<Suspense fallback={<></>}><Dashboard /></Suspense>} />{" "}
          <Route path="/available-slots" element={<Suspense fallback={<></>}><AvailableSlots /></Suspense>} />
          <Route path="/create-event" element={<Suspense fallback={<></>}><CreateEvent /></Suspense>} />
          <Route path="/edit-event/:slotID" element={<Suspense fallback={<></>}><CreateEvent /></Suspense>} />
          <Route path="/avilibility-list" element={<Suspense fallback={<></>}><AvaibilityList /></Suspense>} />

          <Route path="website-management" element={<Suspense fallback={<></>}><WebsiteManageListing /></Suspense>} />{" "}
          <Route path="website-manage-addedit" element={<Suspense fallback={<></>}><AddEditWebsiteManage /></Suspense>} />{" "}
          <Route path="/create-event" element={<Suspense fallback={<></>}><CreateEvent /></Suspense>} />
          {/* <Route path="/avilibility-list" element={<Suspense fallback={<></>}><AvaibilityList /></Suspense>} /> */}


          <Route path="website-content-management" element={<Suspense fallback={<></>}><WebsiteContentManageListing /></Suspense>} />{" "}
          <Route path="website-contentmanage-addedit" element={<Suspense fallback={<></>}><AddEditWebsiteContent /></Suspense>} />{" "}
          <Route path="/calender" element={<Suspense fallback={<></>}><Calender /></Suspense>} />{" "}
          

            <Route path="user-management" element={<Suspense fallback={<></>}><UserManagementListing /></Suspense>} />{" "}

          {/* --- No Page --- */}
          <Route path="*" element={<NoPage />} />{" "}
        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default GetRoutes;