import React from 'react'
import "../../FrontEndPages/FrontEnd.css"


function HomeMiddleBck() {
  return (
            <>
                <div className="home-middle-bck">
                    {/* <div className='commonWidth'> */}
                        <div className='mainWrapper'>
                            <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/middleBlock_demo.webp" alt="" />
                        </div>
                    {/* </div> */}
                </div>

            </>
  )
}

export default HomeMiddleBck