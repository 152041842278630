import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// API call for users-list
export const websiteContentManageList = createAsyncThunk("users/list", async (reqbody) => {

    // console.log("reqbody==>", reqbody);
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api/user-list-and-count", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


// ///////////////////////////////// Status Update //////////////////////////////////
// export const usermanagestatusUpdate = createAsyncThunk("statusUpdateUserManagement", async (reqbody) => {

//     // console.warn("<<<<< usermanagestatusUpdate >>>>>>", reqbody)

//     let req_body = {};
//     req_body = reqbody.editData;
//     console.log("reqbody==", reqbody)
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ "_id": reqbody._id, "status": reqbody.status == 1 ? 0 : 1 })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/user-change-status", requestOptions);
//     const respdataforstatusUpdate = await response.json();

//     return respdataforstatusUpdate;
// });


// ///////////////////////////////// Delete User //////////////////////////////////
// export const userManagementDelete = createAsyncThunk("usermanagementDelete", async (reqbody) => {

//     // console.warn("<<<<< userManagementDelete >>>>>>", reqbody)

//     let req_body = {};
//     req_body = reqbody.editData;
//     console.log("reqbody==", reqbody)
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ "id": reqbody._id })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/delete-user", requestOptions);
//     const respdataforUserDelete = await response.json();

//     return respdataforUserDelete;
// });


// /////////////////////////////////  ResendWelcomeEmail //////////////////////////////////
// export const userManagementResendWelcomeEmail = createAsyncThunk("usermanagement/resendwelcomeemail", async (reqbody) => {

//     let req_body = {};
//     req_body = reqbody.editData;
//     console.log("reqbody==", reqbody)
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ "id": reqbody._id, "email": reqbody.email })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/resend-email", requestOptions);
//     const managementResendWelcomeEmail = await response.json();

//     return managementResendWelcomeEmail;
// });


// /////////////////////////////////  sendResetPassword //////////////////////////////////
// export const sendResetPassword = createAsyncThunk("usermanagement/resetpassword", async (reqbody) => {

//     let req_body = {};
//     req_body = reqbody.editData;
//     console.log("reqbody==", reqbody)
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ "id": reqbody._id, "email": reqbody.email })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/resend-email", requestOptions);
//     const sendResetPassword = await response.json();

//     return sendResetPassword;
// });


// /////////////////////////////////  logMeIn //////////////////////////////////
// export const logMeIn = createAsyncThunk("usermanagement/logmein", async (reqbody) => {
//     console.warn("<<<<<<<< payload from logMeIn thunk >>>>",reqbody)

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ "admin_id": reqbody.admin_id, "log_me_id": reqbody.log_me_id })
//     };
//     const response = await fetch(process.env.REACT_APP_API_URL + "api/log-in-as-user", requestOptions);
//     const logMeIn = await response.json();

//     return logMeIn;
// });




const WebsiteContentManageListingReducer = createSlice({
    name: 'websiteManageListReducer',
    initialState: {
        submitting: false,
        users: [],
        loading: false,
        submitted: false,
        resMessage: null,
        usersList: null,
        successmsg: null,
        success: false,
        failed: false,
        currentEditUserData: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        successAction:false,
    },

    reducers: {

        setSuccessAfterSubmit(state, action) {
            state.success = false;
            state.failed = false;
        },
        currentEditUserData(state, action) {
            state.currentEditUserData = action.payload
        },
        setMessagefterSubmit(state, action) {
            state.messageflag = false;
            state.message = null;
        },
        setTokenRequiredStatus(state, action) {
            state.tokenRequired = false
        },


    },
    extraReducers: {

        /// #################### USER LISting ###############################
        [websiteContentManageList.rejected]: (state, action) => {
            state.loading = false;
            state.successAction=false;

        },
        [websiteContentManageList.pending]: (state, action) => {
            // console.log("action p", action)
            // state.usersList = [];
            state.loading = true;
        },
        [websiteContentManageList.fulfilled]: (state, action) => {
            console.log("action f storedata", action)
            if (action.payload.status === 'success') {

                state.usersList = action.payload.results.res;
                state.message = action.payload.status;
                state.loading = false;
                state.tokenRequired = false;
                state.tokenRequiredMsg = ''
            }
            if (action.payload.status === 'error') {
                if (action.payload.results === "Token required" || action.payload.results === "Invalid Token") {
                    state.tokenRequired = true;
                    state.tokenRequiredMsg = action.payload.results;
                }
            }
        },



        // /////////////////////////// status update ///////////////////////////////
        // [usermanagestatusUpdate.pending]: (state, action) => {
        //     state.loading = true;
        //     state.success = false;
        //     state.failed = false;
        //     state.successAction = false;


        // },
        // [usermanagestatusUpdate.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.resMessage = 'Updated Successfully'
        //         // state.success = true;
        //         state.successAction = true;
        //         // state.success[action.meta.arg.tableId] = true;
        //     }
        // },
        // [usermanagestatusUpdate.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.failed = true;
        //     state.resMessage = 'Something went wrong';
        // },



        // /////////////////////////// Delete User ///////////////////////////////
        // [userManagementDelete.pending]: (state, action) => {
        //     state.loading = true;
        //     state.success = false;
        //     state.successAction=false;

        // },
        // [userManagementDelete.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.resMessage = 'Deleted Successfully'
        //         // state.success = true;
        //         state.successAction = true;

        //         // state.success[action.meta.arg.tableId] = true;
        //     }
        // },
        // [userManagementDelete.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.resMessage = 'Something went wrong';

        // },



        // /////////////////////////// Resend Welcome Email /////////////////////////
        // [userManagementResendWelcomeEmail.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [userManagementResendWelcomeEmail.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.resMessage = 'Mail Sent Successfully'
        //         state.success = true;
        //     }
        // },
        // [userManagementResendWelcomeEmail.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.resMessage = 'Something went wrong';

        // },


        // /////////////////////////// sendResetPassword ///////////////////////////
        // [sendResetPassword.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [sendResetPassword.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.resMessage = 'Reset Password Link Sent Successfully'
        //         state.success = true;
        //     }
        // },
        // [sendResetPassword.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.resMessage = 'Something went wrong';

        // },



        // /////////////////////////// logMeIn ///////////////////////////
        // [logMeIn.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [logMeIn.fulfilled]: (state, action) => {
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.resMessage = 'Successfully Login'
        //         state.success = true;
        //     }
        // },
        // [logMeIn.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.resMessage = 'Something went wrong';

        // },




    }
})

export default WebsiteContentManageListingReducer.reducer;
export const { setSuccessAfterSubmit, setTokenRequiredStatus } = WebsiteContentManageListingReducer.actions;