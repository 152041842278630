import { configureStore } from "@reduxjs/toolkit";
import loginSlice from './Pages/Login/loginReducer'
import tableSlice from "./listing/listReducer"
import searchReducer from "./search/searchReducer";
import calenderEvents from './Pages/Calender/calenderReducer'
import formSlice from './form/formReducer'

import fetchUserDataReducer from './Pages/BackEndPages/userManagement/fetchUserDataReducer'
import userManagment from './Pages/BackEndPages/userManagement/userManagementListing/userManagementListingReducer'
import websiteManageListReducer from './Pages/BackEndPages/websiteManagement/listing/WebsiteManageReducer'
import websiteManageFormReducer from './Pages/BackEndPages/websiteManagement/addEditForm/websiteAddEditReducer'
import websiteContentManageFormReducer from './Pages/BackEndPages/websiteContentmanagement/websiteContentAddEdit/websiteContentAddEditReducer'
import WebsiteContentManageListingReducer from './Pages/BackEndPages/websiteContentmanagement/websiteContentListing/websiteContentManageReducer'
export default configureStore({
    reducer: {
        loginSlice,
        tableSlice: tableSlice,
        formSlice: formSlice,
        tableSlice: tableSlice,
        calenderEventsStore: calenderEvents,
        searchSlice: searchReducer,
        userManagment:userManagment,
        fetchUserDataReducer:fetchUserDataReducer,
        websiteManageListReducer:websiteManageListReducer,
        websiteManageFormReducer:websiteManageFormReducer,
        websiteContentManageFormReducer:websiteContentManageFormReducer,
        WebsiteContentManageListingReducer:WebsiteContentManageListingReducer

    },
});