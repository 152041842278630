import React from 'react'
import "../../FrontEndPages/FrontEnd.css"

function HomeExpenseBlck() {
  return (
   <>
       <div className="expenseSectio_Main">
           <div className="commonWidth">
            <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Expense_Section.png" alt="" />
       </div>
       </div>
   </>
  )
}

export default HomeExpenseBlck