import React, { useEffect, useState } from "react";
import "../FrontEndPages/FrontEnd.css"
import ReactPlayer from "react-player";
import HomeMiddleBck from "./HomeComponents/HomeMiddleBck";
import HomeFormBlck from "./HomeComponents/HomeFormBlck";
import HomeUtilityBlck from "./HomeComponents/HomeUtilityBlck";
import HomeExpenseBlck from "./HomeComponents/HomeExpenseBlck";
import { useSelector } from "react-redux";



function Home() {

//   const formSubmittionstate = useSelector((state) => state.formSlice.formSubmissionState? state.formSlice.formSubmissionState:null);

//   useEffect(()=>{
//     console.log("formSubmittionstate",formSubmittionstate);
// },[formSubmittionstate])
const [videoid] = useState("mog9IyT0CPU"); 

  return (
    <>
      <div className="HomeMain_container">
        <div className="HomeBanner_container">
          <div className="commonWidth">
            <div className="homeBanner_Main">
              <div className="bannerTop">
                <div className="topLogo">
                  <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Bizopp_mainLogo.png" alt="" />
                </div>
                <div className="topRight_text">
                    <h1>Jack Zufelt<span> Mentor to Millions is putting</span></h1>
                    <h1><span> BIG money back in the pockets of</span></h1>
                    <h1><span> American Business Owners.</span></h1>
                </div>
              </div>
              <div className="bannerMiddle">
                  <div className="videoWrapper">
                  {/* ================================================================================================ */}
                  <ReactPlayer width="100%" height="100%" url={`https://www.youtube.com/embed/${videoid}?rel=0`} />
                  {/* ================================================================================================ */}
                  </div>
                  <div className="rightcontent_Wrapper">
                      <div className="left_img">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/demoLeft1.webp" alt=""/>
                      </div>
                      <div className="right_img">
                        <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/demoRight1.webp" alt="" />
                      </div>
                  </div>
              </div>
              <div className="bannerBottom">
                  <div className="bannerBottomInner">
                      <p>TWO STEPS FOR HUGE RETURNS - WE PUT MONEY BACK IN YOUR COMPANY'S POCKETS</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        {/* ====================HomeMiddleBlock=========================== */}
        <HomeMiddleBck/>
        {/* ====================HomeMiddleBlock=========================== */}

        <div className="bottomAllSection_main">
        <HomeFormBlck/>
        <HomeUtilityBlck/>
        <HomeExpenseBlck/>
        <div className="bottomFooter_Block">
          <div className="commonWidth">
            <div className="lastSection_logo">
              <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/bottom_SEctioN.webp" alt="" />
            </div>
          </div>
        </div>
        </div>

        <div className="fooTerCopyright">
            <div className="commonWidth">
                <p>Terms & Conditions Privacy Policy  Copyright@2022</p>
            </div>
        </div>
      </div>
    </>
  );
}

export default Home;
