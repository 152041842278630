import React, { useEffect, useState } from 'react'
import { Form } from '../../../form/Form'
import "../../FrontEndPages/FrontEnd.css"
import StateArray from '../../../assets/json/state'
import { useSelector } from 'react-redux'
import store from '../../../store'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

function HomeFormBlck() {
  const navigate = useNavigate();
  // console.log("StateArray",StateArray);

  const [locationArry,setLocationArry]= useState([])
  const [loading,setloading]= useState(false)
  const formSubmittionstate = useSelector((state) => state.formSlice.formSubmissionState.leadform ? state.formSlice.formSubmissionState.leadform:0);


  

  useEffect(()=>{
    let Newarr=[];
    if(StateArray && StateArray.length>0){
      for(let i in StateArray){
        Newarr.push({val:StateArray[i].name,name:StateArray[i].name})
      }
      setLocationArry(Newarr)

    }



  },[])




  useEffect(()=>{
      console.log("formSubmittionstate",formSubmittionstate);
      if(formSubmittionstate && formSubmittionstate==1){
        setloading(true)

      }
      if(formSubmittionstate && formSubmittionstate==2){
        navigate('/calender')
        setloading(false)
      }
      if(formSubmittionstate && formSubmittionstate==0){
        setloading(false)
        
      }
  },[formSubmittionstate])

  let addForm = [
    //////////////////////////////////// Text Input Event Title ///////////////////////////////
    {
      id: 0,
      label: "First Name",
      name: "firstname",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
    {
      id: 1,
      label: "Last Name",
      name: "lastname",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
    {
      id: 2,
      label: "Phone No",
      name: "phone",
      className: 'formGroup countDiv__column--col12',
      type: "text",
      inputType: 'phone',
      required: "This Field is Required",
      rules: { required: true, minLength: 10 },
      errorMessage: {
        maxLength: "minLength is 10 allowed"
      },

    },
    {
      id: 3,
      label: "Email",
      name: "email",
      className: 'formGroup countDiv__column--col12',
      type: "text",
      rules: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      errorMessage: {
        required: "This Field is Required",
        pattern: "Enter a valid email"
      },
    },
    {
      id: 4,
      label: "State",
      name: "state",
      className: "formGroup eventTitle countDiv__column--col12",
      type: 'select',
      // checkbox: true,
      other: true,
      values: locationArry,
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
    {
      id: 5,
      label: "City",
      name: "city",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
   
    
    {
      id: 6,
      label: "Employee Size",
      name: "employee_size",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
    {
      id: 7,
      label: "Years in Business",
      name: "years_in_business",
      className: "formGroup eventTitle countDiv__column--col12",
      type: "text",
      inputType: "number",
      rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        maxLength: "MaxLength length 20 allowed",
      },
      defaultValue: ''
    },
    {
      id: 8,
      heading: "Check Service Interests",
      name: "service_interests",
      className: 'multipleCheckbox',
      hint: 'In months',
      type: 'multipleCheckbox',
      values: [{ key: "All", val: 'All' }, { key: 'Credit Card Processing', val: 'Credit Card Processing', }, { key: 'Utilities', val: 'Utilities' }, { key: 'Wireless', val: 'Wireless', }, { key: 'Workers Comp', val: 'Workers Comp' },{ key: 'Laundry & Linen', val: 'Laundry & Linen', }],
      // rules: { required: true },
      errorMessage: {
        required: "This Field is Required",
        custom: "Value is Invalid"
      },
    },
    
  ]


  const formData = {
    id: "leadform",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/submit-intake-form",
    // urlPathOnSuccessfulFormSubmission: "/Calender",
    formButtonClass: "submitbtnsection",
    fields: addForm,
    formAdditionalSubmissionData: {

      //   username: `${item.firstname} ${item.lastname}`,
      //   useremail: `${item.email}`,
      //   id: params.slotID ? params.slotID : null
    },
  };
  return (
    <>

<div className="formSction_maimWrp">
        <div className="commonWidth">
          <div className="formSction_subWrp">
            <div className="leftSection_texts">
              <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/FormAlignTExts.png" alt="" />
            </div>
            <div className="formWrapMain">
              <div className="formWrapperMain">
                <div className="formHeader">
                  <h2>SPEAK WITH AN ASPIRE</h2>
                  <h2>EXPENSE REDUCTION AND </h2>
                  <h2>REFUND EXPERT RIGHT AWAY</h2>
                </div>
                <Form formData={formData} />

                <div>
      {loading && <LinearProgress sx={{ margin: "5px" }} />}
      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    


    </>
  )
}

export default HomeFormBlck