import { Button } from '@mui/material'
import React from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";

import PersonIcon from '@mui/icons-material/Person';
import { withCookies, Cookies, useCookies } from "react-cookie";

import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import "./Layout.css";

export default function BackendHeader() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userInfo, setuserInfoCookie] = useCookies(["userinfocookies"]);
  const [selectMenu, setselectMenu] = React.useState(null);


  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl);
  const handleClick = (val, event) => {
    console.log("val==>", val);
    console.log("event==>", event);
    setselectMenu(val);
    console.log('event.currentTarget==>', event.currentTarget);
    console.log('All==>', event.currentTarget, open, open1, anchorEl, selectMenu, val);
    setAnchorEl(event.currentTarget);
    console.log('All after==>', anchorEl, selectMenu, val);

  };
  const handleClose = () => {
    console.log('before close ..', open, open1, anchorEl, selectMenu)
    setAnchorEl(null);
  };


  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("location ====", splitLocation);


  
  return (
    <div>
      <div className='admin_topbody'>
        <Link to="/dashboard">  <img src="https://all-frontend-assets.s3.amazonaws.com/bizopp-goldrush/logo.webp" className='adminlogo'></img></Link>

        <div className='topuserblock'>

          <PersonIcon className='PersonIcon' /> <h1>  User Name</h1>
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(evt) => handleClick(1, evt)}
          >

            <KeyboardArrowDownIcon className='userarrowicon' />

          </Button>


          {selectMenu === 1 &&

            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >

              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={() => handleClose()}>Logout</MenuItem>
            </Menu>

          }


        </div>



      </div>


      <div className="admin_topNav">
        <Button className={splitLocation[1] === "dashboard" ? "active" : ""}><Link to="/dashboard"> Dashboard  </Link>{" "}</Button>
        <Button className={splitLocation[1] === "user-management" ? "active" : ""}><Link to="/user-management">Users </Link>{" "}</Button>
        <Button className={splitLocation[1] === "website-management" ? "active" : ""}><Link to="/website-management"> Website Mangement  </Link>{" "}</Button>
        <Button className={splitLocation[1] === "website-content-management" ? "active" : ""}><Link to="/website-content-management"> Website Content Mangement  </Link>{" "}</Button>

      </div>
    </div>
  )
}
