import React from 'react'
import "../../FrontEndPages/FrontEnd.css"


function HomeUtilityBlck() {
  return (
    <>
        <div className="unilityBlock_main">
        <div className="commonWidth">
            <img src="https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/utility_bill_block" alt='' />
            </div>
        </div>
    </>
  )
}

export default HomeUtilityBlck