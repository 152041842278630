import { Outlet, Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
 
import { setLogedinUserInfo } from "./Pages/Login/loginReducer";
import store from "./store.js";
import { Box, LinearProgress } from "@mui/material";
import { useState } from "react";
import BackendHeader from "./Pages/Layout/BackendHeader";
import BackendFooter from "./Pages/Layout/BackendFooter";
import Header from "./Pages/Layout/Header";
import Footer from "./Pages/Layout/Footer";

 


let storedata = null;


const Layout = () => {

 

  const { pathname } = useLocation();
  console.log("pathname==>", pathname);
  const [loading, setLoading] = useState(false);

  store.subscribe(() => {
    storedata = store.getState();
     

  });

  return (
    <>
      {loading && <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>}
      {pathname !== "/login" && pathname != "/"   && pathname !== "/404page" &&  (
        <div>
          <BackendHeader/>

        </div>
      )}
      { pathname == "/" &&  (
        <div>
          <Header/>

        </div>
      )}

      
        <Outlet />
     


      {loading && <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>}
      {/* {pathname !== "/login" && pathname != "/"   
       ( */}
        <div>
          <BackendFooter/>
        </div>
      {/* )} */}

      { pathname == "/" &&  (
        <div>
          <Footer/>

        </div>
      )}

    </>
  );
};

export default withCookies(Layout);
