import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { createAsyncThunkHelper } from "../helper/helperFunctions";
import { setTableData } from "../listing/listReducer";
import store from "../store";


// //////////////////////// Create Async Thunk Helper /////////////////////////////
const createAsyncThunkHelper = async({
        BASE_URL,
        endPoint,
        reqBody,
        restBodyAttachment,
        restResponseAttachment,
        bodyWrapper,
        endPointType
    }) => {
        console.log("From Craete Async Thunk Helper", BASE_URL, endPoint, reqBody, restBodyAttachment, restResponseAttachment, bodyWrapper, "endPointType==>", endPointType);
        let newReqBody = {...reqBody };

        if (
            typeof restBodyAttachment === "object" &&
            restBodyAttachment !== null &&
            Object.keys(restBodyAttachment).length > 0
        ) {
            for (let key of Object.keys(restBodyAttachment)) {
                newReqBody[key] = restBodyAttachment[key];
            }
        }

        /////////////////// for Body Wrapper ////////////////////
        if (bodyWrapper !== "" && bodyWrapper) {
            console.log(newReqBody);
            newReqBody = {
                [bodyWrapper]: newReqBody,
            };
        }
        console.log("newReqBody---------------->", newReqBody)
        const requestOptions = {
            method: endPointType ? endPointType : "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(newReqBody),
        };

        ////////////////////////////// delete body if GET method ////////////////////////////////
        if (endPointType !== undefined && endPointType === 'GET') {
            delete requestOptions.body;
        }
        const response = await fetch(
                `${BASE_URL}${endPoint !== "" && endPoint !== undefined ? `${endPoint}` : ""
    }`,
    requestOptions
  );
  let result = await response.json();



  /////////////////////////////////////////////////////////////////////////////////////////// Need modification of without object response ////////////////////////////////////////////////////////

  // if (typeof (result) !== 'object') {
  //   result = { count: result }
  // }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (
    typeof restResponseAttachment === "object" &&
    restResponseAttachment !== null &&
    Object.keys(restResponseAttachment).length > 0
  ) {
    for (let key of Object.keys(restResponseAttachment)) {
      result[key] = restResponseAttachment[key];
    }
  }

  return result;
};


export const fetchAutocompleteSearchList = createAsyncThunk("search/autoCompleteList", createAsyncThunkHelper);

export const fetchSearchResult = createAsyncThunk("search/result", createAsyncThunkHelper);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    autoCompleteList: {},
    loading: {},
    formSubmissionState: {},
    searchBody: {},
  },
  reducers: {

  },
  extraReducers: {
    /////////////////////////////// Fetch Search Result ////////////////////////////////////////
    [fetchSearchResult.pending]: (state, action) => {
      console.log("Search Pending", action);

    },
    [fetchSearchResult.fulfilled]: (state, action) => {
      console.log("Search Fullfilled", action);
    },
    [fetchSearchResult.rejected]: (state, action) => {
      console.log("Search Rejected", action);
    },
    //////////////////////////// Fetch Auto Complete List /////////////////////////////////////
    [fetchAutocompleteSearchList.pending]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = true;
    },
    [fetchAutocompleteSearchList.fulfilled]: (state, action) => {
      state.loading[action.payload.autoCompleteId] = false;
      console.log(action.payload);
      const initialResult = [];
      const result = action.payload.results.res.reduce((acc, eachResult) => {
        acc.push({ key: eachResult.key, label: eachResult.label });
        return acc;
      }, initialResult);
      state.autoCompleteList[action.payload.autoCompleteId] = result;
    },
    [fetchAutocompleteSearchList.rejected]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = false;
    },
  },
});

export const { } = searchSlice.actions;

export default searchSlice.reducer;