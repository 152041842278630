import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const websiteManageData = createAsyncThunk("webmanage/edit", async(reqBody) => {
    console.warn("<<<<<< inside fetchUserDataReducer >>>>", reqBody)
    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
        // _id:reqBody,
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api/fetch-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

const websiteManageFormReducer = createSlice({
    name: 'websiteManageFormReducer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        successMsg: null,
        errorMsg: null,
        tokenRequired: false,
        tokenRequiredMsg: '',
        fetchUserDataReducer: null
    },
    reducers: {
        setInitData(state,action){
        state.loading= false
        state.success= false
        state.error= false
        state.successMsg= null
        state.errorMsg= null
        state.tokenRequired= false
        state.tokenRequiredMsg= ''
        state.fetchUserDataReducer= null
        },
    },
    extraReducers: {
        [websiteManageData.pending]: (state, action) => {
            state.loading = true
        },
        [websiteManageData.fulfilled]: (state, action) => {
            console.log("dash action ", action)
            state.loading = false;
            state.success = true;
            state.fetchUserDataReducer = action.payload.results[0];
        },
        [websiteManageData.rejected]: (state, action) => {
            state.loading = false
        },
    }
});



export default websiteManageFormReducer.reducer;
export const {setInitData} = websiteManageFormReducer.actions;